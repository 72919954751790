import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Process = makeShortcode("Process");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-debt---the-first-5000-years",
      "style": {
        "position": "relative"
      }
    }}>{`🕋 Debt - the first 5000 years`}<a parentName="h1" {...{
        "href": "#-debt---the-first-5000-years",
        "aria-label": " debt   the first 5000 years permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <Video src="https://www.youtube-nocookie.com/embed/CZIINXhGDcs" mdxType="Video" />
    <p>{`This is a long video, but it has been selected nonetheless for two important reasons:`}</p>
    <Process mdxType="Process">
      <p>{`It presents a different, and perhaps even more informed, view of the development of money
than either Szabo or Antonopoulos, which is worth taking into account.`}</p>
      <p>{`Too often, the people disrupting any industry don't understand deeply what it is they're
disrupting. This is definitely the case with cryptocurrencies and the current financial system.
It really is well worth your time to stop and become familiar with more history so that you can
understand why we are where we are, what led us here, and - only then - what solutions might
actually benefit you and those you care about.`}</p>
    </Process>
    <p>{`We'll provide you with a highly summarized version of the talk below, but recommend that you set
aside some time and watch it in full.`}</p>
    <h2 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit into Kernel?`}<a parentName="h2" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We're trying to provide as broad a perspective as possible on money, speech, value and trust:
the core ideas which lie at the heart of building global, borderless, ownerless and decentralized
products, services and protocols. `}</p>
    <p>{`Most importantly, you can't just hate on debt: you need to understand what it actually is and
how we got to a world which runs on it and where we sit with a current global debt of $307
trillion (it has increased $126 trillion since we began Kernel in 2020!).`}</p>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <h3 {...{
      "id": "debt-and-morality",
      "style": {
        "position": "relative"
      }
    }}>{`Debt and morality`}<a parentName="h3" {...{
        "href": "#debt-and-morality",
        "aria-label": "debt and morality permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"`}{`[The book]`}{` grapples with a moral and political problem, which is: 'What is this hold that
the idea of debt has over people's imaginations; that morality itself comes to be thought of
as a matter of paying one's debts?'"`}</p>
    </blockquote>
    <p>{`Graeber begins with a personal story and describes the "Drop the Debt" Campaign, or "Jubilee
2000". He was perplexed by most people's seemingly common-sensical notion that everyone absolutely
should be expected to pay their debts, no matter what. He feels differently, and so began
researching the history of debt, and discovered some surprising things. In particular:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"No-one has ever written a history of debt `}{`[...]`}{` You do find histories of money, which turn
out to be mostly about coinage, which is interesting given that the vast majority of transactions
through history have been on credit `}{`[...]`}{` and you do find histories of credit, but it's not
exactly the same thing `}{`[...]`}{` I have found that `}<strong parentName="p">{`most of our basic, common-sense assumptions
about debt are completely wrong`}</strong>{` and that the moral ambivalence typified by the 'but you just
`}<em parentName="p">{`have`}</em>{` to pay your debts' conversation has been happening for about 5,000 years."`}</p>
    </blockquote>
    <p>{`We move from here into a discussion of Plato's `}<em parentName="p">{`Republic,`}</em>{` the question "What is justice?" and
how the book - like most major religions - takes the structure of 'OK, it's not that, maybe
something else then?'. This is the style of thinking we pointed to in `}<a parentName="p" {...{
        "href": "/learn/module-0/trust/#trust-and-evidence"
      }}>{`trust`}</a>{` -
you approach the truth only by negation. On this note:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Debt, guilt, and sin are actually the same word in Sanskrit - i.e. life itself is a cosmic
debt we owe to the gods `}{`[...]`}{` You owe a debt to your parents, so you become a parent; you owe a
debt to the sages, so you become wise; you owe a debt to humanity, so you become humane. But
this becomes absurd when we extend it - you're not really paying it back, even though the debt
is annihilated by such action `}{`[...]`}{` Once you figure out what `}{`[the Vedas]`}{` are really saying it's
that, in fact, you are the cosmos and so there can be no such thing as debt. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"The same thing happens in the Bible. In Arameic, the word for debt and sin is also the same
word. Actually the Lord's Prayer - which we remember translated into terms of private property -
is 'Forgive us our debts, just as we forgive those whom are indebted to us' `}{`[...]`}{` `}<strong parentName="p">{`So what is
sacred isn't actually paying back debts, it's forgiveness of debts`}</strong>{`."`}</p>
    </blockquote>
    <p>{`Put aside any institutional religious difficulties the above passages may present you with for
a moment and see this point as it really is.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Debt forgiveness is, in fact, a tradition going back to Mesopotamia and the Biblical Jubilee.
It was considered a divine act `}{`[...]`}{` Over and over, we find this situation in our civilizational
stories where people start with the assumption that debt and morality are the same, and then they
are forced to accept that they aren't and have to cast around for some other basis for a
universal morality, often encountering great difficulty along the way. It's even more
complicated, because when you do think of debt as sin, there is a question of `}<em parentName="p">{`who is the sinner?`}</em>{`"`}</p>
    </blockquote>
    <p>{`Graeber references a Japanese Buddhist story to highlight this point. Stories like these are best
listened to yourself (14:23). The problem it highlights is the moral peril on either side of a
debt transaction and the ambivalence it reveals in the very nature of human sociality. Most
interestingly, if we're concerned with the level of human exchange, `}<strong parentName="p">{`then we only have a
relationship with the other when the exchange is incomplete, i.e. when there is debt`}</strong>{`.`}</p>
    <p>{`The key to maintaining relationships in many older traditions is based on this ambivalence.
Someone will give you something and the expectation is that you will give them something in
return, though not something of `}<em parentName="p">{`exactly the same`}</em>{` value as that would indicate you no longer
wish to relate with them. A healthy community is one in which everybody is a `}<strong parentName="p">{`little bit`}</strong>{` in
debt to everyone else - it gives us an excuse to see each other!`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: The great religions reveal that, ultimately, there can be no debt because you yourself are the cosmos. However, debt is also a condition of human relationship. In this sense, a healthy community is one in which ...?`}</p>
        <p>{`Everyone is a little bit in debt to everyone else.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "communal-credit",
      "style": {
        "position": "relative"
      }
    }}>{`Communal credit`}<a parentName="h3" {...{
        "href": "#communal-credit",
        "aria-label": "communal credit permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"97% of all transactions in England up until the 17th Century were by credit. This was partly
because there wasn't a lot of currency floating around, and partly because actual money is
associated with violence, war and soldiers. Nice people operate on credit and trust."`}</p>
    </blockquote>
    <p>{`Of course, keeping a running ledger in your head of all your debts quickly becomes challenging,
hence every half-year or year they would hold "communal reckonings", cancel the debt they could,
and pay back the amounts still outstanding after a few loops.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"That's community! Community means everybody owes everybody else something and everybody
remembers what they owe and eventually they all settle it and start over again. In this sense,
debt isn't a terrible thing, but there's still this shadow of `}<em parentName="p">{`sin`}</em>{` floating over it. Where
does that come from? `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`More generally: a debt is a promise which you can phrase in exactly quantifiable terms.
Because you can quantify it, it's impersonal, and therefore transferable. According to some
theories, this is what money actually is: a transferable promise. One of the great questions is
how this came about? Why is there a need to quantify promises precisely?"`}</p>
    </blockquote>
    <p>{`Adam Smith attempted a detailed description of this in 1776 in `}<em parentName="p">{`The Wealth of Nations,`}</em>{` which
has been accepted as the Economics 101 argument everyone now knows (and which you find in
`}<a parentName="p" {...{
        "href": "/learn/module-2/shelling-out"
      }}>{`Shelling Out`}</a>{`). However, it's not true if you take an accurate
anthropological view of history. This false story starts with barter, which runs into the
problem of coincidence of wants and the need to settle on one general equivalent and, through a
virtuous feedback loop, this then becomes the standard and turns into money. `}</p>
    <p>{`The only problem with this story is that `}<em parentName="p">{`we have never found any evidence`}</em>{` for an entirely
barter-based society. The core issue here is the assumption that neolithic people would've only
engaged in spot trades, which is not the case if we look at the anthropological evidence. `}</p>
    <p>{`In most societies, if someone praises your possession, it's almost impossible not to give it to
them. Like debt, this phenomenon exerts a strange moral power over many people, even in the
modern world. Graeber tells a Maori story here also worth listening to yourself (26:21).`}</p>
    <p>{`In these sorts of communities, there is definitely a quantified ranking system: a cow is roughly
like a canoe is roughly like a good necklace. People can shirk this in all sorts of ways
(because favors need not be repaid in a purely material sense), but if you do, you earn a
reputation as a cheap skate, which is devastating in small communities. However, it is not
possible to say `}<em parentName="p">{`exactly`}</em>{` how cheap you are. Graeber then asks:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"How does this rough credit system - which we `}<em parentName="p">{`actually observe in places without money`}</em>{` -
turn into a system with exact proportional equivalence? The answer seems to be: when someone is
really angry. When people are being nice to each other, there's no need to calculate `}{`[...]`}{` Very
often it is the case, even in places where you don't have markets for goods, you do have very
elaborate fines for personal damages `}{`[...]`}{` what they call `}<em parentName="p">{`the barbarian law code`}</em>{` `}{`[...]`}{` So, in
legal situations where there is the danger of a feud or outbreak of violence, people do need to
quantify. This is the first circumstance in which something like money emerges." `}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: According to the anthropological evidence, have most transactions in our history occurred using collectibles and coins, or on credit?`}</p>
        <p>{`Credit.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "currency-and-violence",
      "style": {
        "position": "relative"
      }
    }}>{`Currency and violence`}<a parentName="h3" {...{
        "href": "#currency-and-violence",
        "aria-label": "currency and violence permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"The association between money and violence is a constant and it helps to explain a lot about
why debt takes on this incredibly powerful moral hold. If you are in a situation of violent
inequality, probably the most effective technique ever invented to make it seem not only moral,
but to make it seem like the victim is to blame, is to `}<strong parentName="p">{`frame it in the language of debt`}</strong>{`."`}</p>
    </blockquote>
    <p>{`Mafiosi and the heads of conquering armies understand this all too well. Interestingly, taxes
were only placed on conquered populations: they owe the debt of their lives and the conquerors
get to be magnanimous benefactors while the victims end up subjugated for long periods. It's
happened throughout history. However, history also proves that this eventually blows up in the
victor's faces. This is because debt occurs between two parties who are at least potentially
equal. Moses Finlay said that there was only one revolutionary program throughout antiquity,
which was 'Cancel the debts and redistribute the land.'`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Why does debt motivate people across all ages in this way? `}<strong parentName="p">{`Because it implies that you're
equal!`}</strong>{` This is the inevitable conclusion of claiming that debt is morality. Suddenly the
language of debt becomes all-pervasive, because you have to use the master's language and I
think that's what we're dealing with in all these religious and philosophical texts which start
by saying that debt is morality, but then end up saying, 'well, actually, it's not'. It's also
why all this moral language becomes financial language, if you think about `}<em parentName="p">{`redemption`}</em>{` and
`}<em parentName="p">{`reckoning`}</em>{` and so on. We're stuck with this language of debt, except that it periodically blows up."`}</p>
    </blockquote>
    <p>{`Remember that the story of barter → money → credit is not only wrong, it's backwards. We go back
to ancient Sumer to discuss not just the clay tokens used to make the documents `}<a parentName="p" {...{
        "href": "/learn/module-1/playdough-protocols"
      }}>{`Szabo mentioned last week`}</a>{`,
but the fact that they're recording compounding interest rates. The evidence for this version of
the story is `}<em parentName="p">{`scales`}</em>{`, which - despite the available technology - were not accurate enough for
small, daily amounts: i.e. people were running tabs.`}</p>
    <p>{`To avoid periodic social upheavals, or after winning wars etc., kings would declare a "clean
slate" and wipe all (consumer) debt. This becomes systematized in the Biblical jubilee (every
7 to 49 years, depending on your reading).`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 The first recorded word for freedom in any human language is the Sumerian 'ama-gi' which
literally means 'return to mother', because that's what happened. `}<strong parentName="p">{`Debt cancellation meant all
the debt peons got to go home`}</strong>{`.`}</p>
    </blockquote>
    <p>{`It's bold because it's important.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: According to Moses Finlay, what is the only revolutionary program throughout antiquity?`}</p>
        <p>{`Cancel the debts and redistribute the land.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "virtual-money-coins-and-an-ancient-global-system",
      "style": {
        "position": "relative"
      }
    }}>{`Virtual money, coins, and an ancient global system`}<a parentName="h3" {...{
        "href": "#virtual-money-coins-and-an-ancient-global-system",
        "aria-label": "virtual money coins and an ancient global system permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"This virtual money system comes first, which is always entertaining because people have this
tendency to write about virtual money as a new phenomena, as if we're in this brave new world,
but actually `}<em parentName="p">{`it's the original form of money`}</em>{`. Coins come later. Barter only tends to show up
where you have people who are used to using money, but can't get their hands on any `}{`[...]`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Coins are an interesting case, because they appear in different places using different
physical techniques, though the social situations were the same. In all cases, money in the
physical sense - currency - is invented to pay soldiers."`}</p>
    </blockquote>
    <p>{`Graeber explains the reasons for this and demonstrates how cash markets - where they do emerge -
tend to be in the regions where armies are parked. Governments quickly adopt this (another
aspect of this story more convincing than barter, which cannot account for why governments
demanded taxes in physical currency), because - in essence - it allows them to employ the entire
population to get armies the things they need to survive and fight.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Most transactions in history have been on credit, and coins only come about as a means of funding armies. So, was the original form of money "real" or virtual?`}</p>
        <p>{`Virtual (extra points for those who quote Ecclesiastes: "there is nothing new under the sun")`}</p>
      </Card>
    </Flash>
    <blockquote>
      <p parentName="blockquote">{`"Money and taxes have been used through the centuries as ways of creating markets. Which is
interesting because we have this assumption that markets and governments are opposed principles
and that political choice is largely about which one we are going to slant to: free markets or
governments `}{`[...]`}{` Historically, in fact, markets tend to be created `}<em parentName="p">{`by`}</em>{` governments as a
side-effect of military operations.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Coinage was invented around 600 BC in what Karl Jaspers called 'the axial age'. He pointed
out that most major schools of philosophy and world religions developed in the exact same time
and places where we invented coinage `}{`[...]`}{` World religions arise largely as `}<strong parentName="p">{`peace movements`}</strong>{`
against these empires that are using coins to fund armies."`}</p>
    </blockquote>
    <p>{`We're back to complementary opposites: in exactly the same place where we see the development of
impersonal markets based on physical currency, we see the development of religions which
emphasize the lack of importance in material goods. Gradually though, the empires dissolve, try
to adopt religion - which doesn't really work - and we get the Middle Ages where all the gold is
put into churches and monasteries, most people go back to local trust, and religions take over
the regulation of elaborate credit systems.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"For example, most transactions in the market in Basra from 800 or 900 were done by cheque.
This gives an interesting perspective on global markets; which are also nothing new. `}<em parentName="p">{`Cheque`}</em>{` is,
in fact, an Arabic word `}{`[and]`}{` `}<em parentName="p">{`cash`}</em>{` is a Tamil word for Chinese money. How did that happen?
`}{`[...]`}{` Again, it's not true that we reverted to barter in the Middle Ages `}{`[...]`}{` We used Roman
money until Charlemagne, who never actually got around to making pounds though people still
used them to measure. They actually called it `}<strong parentName="p">{`imaginary money:`}</strong>{` an ideal system which spanned
Europe with which you could trade on the basis of money that didn't exist. We even had a
phenomenon called 'crying up' or 'crying down' the currency, by which the king dictated value."`}</p>
    </blockquote>
    <p>{`Please take a moment to smile at the historical `}<strong parentName="p">{`fact`}</strong>{` of imaginary money and a famous king who just, kinda, never got around to minting the actual stuff, though he did deign to cry its price up or down according to his needs. Graeber then discusses paper money in China and the move toward public currency, versus privatization in the Islamic world. Most interestingly, it was Islam which led to the first real free market ideology.`}</p>
    <h3 {...{
      "id": "its-not-about-competition",
      "style": {
        "position": "relative"
      }
    }}>{`It's not about competition`}<a parentName="h3" {...{
        "href": "#its-not-about-competition",
        "aria-label": "its not about competition permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"Adam Smith got most of his ideas and best lines from medieval Islam - like the pin factory,
which actually comes from Al-Ghazzali in 1100 who spoke of a needle factory. It was made
possible by `}<em parentName="p">{`shariah`}</em>{` - civil law which bans usury and operates outside the purview of the state.
As a result, we got the first true free markets. You could write a cheque in Mali and cash it in
Indonesia and we get, for the first time, the idea that markets can run by themselves. `}<strong parentName="p">{`But`}</strong>{`,
since they have a `}<em parentName="p">{`real`}</em>{` free market, which isn't enforced by the state, they don't assume it's
about competition. `}<strong parentName="p">{`The assumption is that it's all an extension of mutual aid`}</strong>{`."`}</p>
    </blockquote>
    <p>{`The world worked like this, except for Western Europe which was a barbarian backwater, until
1492 when a lot of new gold flowed in from the Americas; slavery came back, large empires came
back and - to cut a long story short - that's the period which is ending now. 1971 and Bretton
Woods is taken by most to be the key point of transition. We get credit cards and cashless
transactions taking over in a generation, far more focus on financial capital, "credit is gonna
save the world and micro-credit will save the third world", 401k's and mortgages and everything
else which blew up in 2008. The question is, again, `}<em parentName="p">{`why`}</em>{`?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"The reason is because we're doing things backward. If we look at this from a broad, historical
perspective, what we learn is that whenever you have a system of virtual money - in which money
is not a thing, but a promise - you have to set up some mechanism to protect debtors. You could
have periodic debt cancellation, or anti-usury laws and profit-sharing. Usually it's some kind
of giant cosmological system beyond the purview of any state or king. We do set up giant,
overarching institutions like this today, but we do so in the form of the IMF, which protects
creditors against debtors. All these backward institutions produce the idea that nobody should
ever default, which is absurd."`}</p>
    </blockquote>
    <p>{`In such a system, the distinction between actual slavery, and being so in debt that you sell
yourself or your time every day is just a legalistic one. However, we're talking about 500 year
cycles here, people are beginning to wake up, and we still have time to get it right.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"If democracy is to mean anything, it ought to mean that everybody gets to weigh in on what
sort of promises are made, what sort of promises are kept, and - when circumstances change -
what sort of promises are renegotiated. That's the political moment that we're in right now."`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Islamic law meant free markets could operate outside of the purview of any state. Such markets were not about competition, but operated under the assumption that free exchange is an extension of what?`}</p>
        <p>{`Mutual aid.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "questions",
      "style": {
        "position": "relative"
      }
    }}>{`Questions`}<a parentName="h3" {...{
        "href": "#questions",
        "aria-label": "questions permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`The Q&A begins at 59:40. The first question has to do with what sort of risk-free rate of return
on investment Graeber would impose in his perfect society, considering things like natural
spoilage. That is, does money necessarily have to grow?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Our entire perspective on what money should do goes back to arguments in the Middle Ages.
Since usury was illegal, they came up with interest, which was really a late fee. You were not
renting money, which is evil, but charging people for failure to return it on the basis that you
could have earned ~5% by investing it had they returned it `}{`[...]`}{` But, I actually do think a jubilee
would be reasonable. I end the book by throwing that out `}{`[...]`}{` because the very idea would allow
us to reconceptualize what we're doing and realise that `}<strong parentName="p">{`money isn't what we thought it was`}</strong>{`."`}</p>
    </blockquote>
    <p>{`The Boston Consulting Group actually modelled the idea of a jubilee and concluded that it would
cause significant economic disruption, but rather less than if we didn't do it.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"I think we should start thinking about radically different forms of money entirely.
Interest-free systems would be possible. The reason Islamic Banking doesn't work is because not
everybody's doing it."`}</p>
    </blockquote>
    <p>{`Is there any precedent for the sheer amount of debt across the globe today?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Probably not, though there have been many speculative bubbles. The real mystery is `}<em parentName="p">{`who is
it all owed to?`}</em>{` Everyone seems to be in debt, which is puzzling, in a way." `}</p>
    </blockquote>
    <p>{`Graeber references the Midnight Notes Collective and their notion of the 2 phases of capitalism
after WWII. Since the 70's there has been a crisis of inclusion in the social benefits and
welfare handed out in phase 1 and "the deal" is clearly off. Hence, we decouple wages and
productivity, and give everyone political rights (which don't imply economic benefits anymore),
but you can have `}<em parentName="p">{`credit`}</em>{`. Again, everyone wants in on this deal, and the system inevitably implodes.`}</p>
    <p>{`Next question: if we protect the debtors, why pay back debt at all?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"If the government had paid back mortgages, that would also have bailed out the banks, but
they chose not to and gave the money directly to the banks. This is a pattern we see throughout
world history: `}<strong parentName="p">{`debt means something totally different depending on who it is between`}</strong>{`.
Between equals, debt is just a broad moral feeling that you should come through. Between the
big players, it's the same story. However, what seems to be treated as 'sacred' is not debt
between equals, but debts between the people on top and the people on the bottom. All of a
sudden it takes on a different color `}{`[...]`}{` `}<strong parentName="p">{`The philosophy of debt as something which cannot
be forgiven only ever crops up in situations of structural coercion and extreme inequality.`}</strong>{`"`}</p>
    </blockquote>
    <p>{`How much of our sense of debt is biological, how much of it is from society and how much of it
is regulatory? The answer is a bit tangential, but importantly, Graeber says:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"When you try to justify things in terms of the 'big picture', that's when you fall back on
the language of debt and reciprocity. People tend to use this language to justify social
relationships which operate according to completely different principles."`}</p>
    </blockquote>
    <p>{`Using massive foreclosures in SF as an example, what would it look like to build a movement for
a jubilee?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"The vast majority of social movements throughout history have been about debt `}{`[...]`}{` The
problem with organizing debtors politically is that debt is very alienating. Most people with
student loan debt or mortgage debt don't actually know many others in the same situation and
there is no forum to compare notes and come up with common strategies."`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Critically, debt as something which cannot be forgiven only ever crops up in situations of what?`}</p>
        <p>{`Structural coercion and extreme inequality.`}</p>
      </Card>
    </Flash>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      